import { useLiveQuery } from "dexie-react-hooks";
import { FC, useCallback, useState } from "react";
import { db } from "../db";
import { MusicVideo } from "../types";
import { MusicVideoForm } from "./MusicVideoForm";
import { AudioDisplay } from "./AudioDisplay";
import { ShotList } from "./ShotList";
import { ActiveProject } from "./ActiveProjectContext";
import { css } from "@emotion/react";
import { TopNav } from "./TopNav";
import Icon from "@mdi/react";
import { mdiCog, mdiCogOutline } from "@mdi/js";
import { NewSceneController } from "./NewSceneController";
import { NewShotController } from "./NewShotController";
import { HotkeysProvider } from "react-hotkeys-hook";
import { TooltipWrapper } from "./Tooltip";
import { NewNoteController } from "./NewNoteController";

interface Props {
  id: string;
}

const layoutCss = css`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const containerCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

const shotListHeaderCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
`;

const actionsContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 10px;
`;

export const Project: FC<Props> = ({ id }) => {
  const projectId = parseInt(id, 10);

  const video = useLiveQuery(
    () => db.videos.where({ id: projectId }).first(),
    [projectId]
  );

  const onUpdate = useCallback(
    (update: MusicVideo) => {
      db.videos.update(projectId, update);
    },
    [projectId]
  );

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const onToggleSettings = useCallback(() => {
    setIsSettingsVisible((b) => !b);
  }, []);

  const settingsIcon = isSettingsVisible ? mdiCog : mdiCogOutline;

  if (!video) {
    return <div>Cannot find project {id}.</div>;
  }

  return (
    <ActiveProject projectId={projectId}>
      <HotkeysProvider initiallyActiveScopes={["project"]}>
        <div css={layoutCss}>
          <TopNav hasHomeLink>
            <div>
              <strong>{video.title}</strong>
            </div>

            <TooltipWrapper content="Project settings" position="west">
              <button onClick={onToggleSettings}>
                <Icon path={settingsIcon} />
              </button>
            </TooltipWrapper>
          </TopNav>

          <div css={containerCss}>
            {isSettingsVisible && (
              <MusicVideoForm
                video={video}
                submitLabel="Update"
                onSubmit={onUpdate}
              />
            )}

            <AudioDisplay projectId={projectId} audio={video.audio} />

            <div css={shotListHeaderCss}>
              <strong>Shot List</strong>
              <div css={actionsContainerCss}>
                <NewSceneController />
                <NewShotController />
                <NewNoteController />
              </div>
            </div>

            <ShotList musicVideoId={projectId} />
          </div>
        </div>
      </HotkeysProvider>
    </ActiveProject>
  );
};
