import { FC } from "react";
import { NoteForm } from "./NoteForm";
import { Note } from "../types";
import { db } from "../db";
import { useActiveProjectId } from "./ActiveProjectContext";

interface Props {
  onCreated?: () => void;
}

export const NewNoteForm: FC<Props> = ({ onCreated }) => {
  const musicVideoId = useActiveProjectId();
  const onCreate = async (note: Note) => {
    await db.notes.add({ ...note, musicVideoId });

    if (onCreated) {
      onCreated();
    }
  };

  return <NoteForm submitLabel="Add Note" onSubmit={onCreate} />;
};
