import { Route } from "wouter";
import { css } from "@emotion/react";
import { TopNav } from "../components/TopNav";
import { flexboxCss } from "../styles/layout";
import { SyncClientController } from "../components/SyncClientController";

const containerCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px;
  justify-content: center;
  height: 100%;
`;

export const WebIntentSyncRoute = () => (
  <Route path="/webintent/sync/:hostId">
    {(params) => (
      <div css={flexboxCss({ direction: "column", height: "100vh" })}>
        <TopNav></TopNav>
        <div css={containerCss}>
          <SyncClientController hostId={params.hostId} />
        </div>
      </div>
    )}
  </Route>
);
