import { MouseEvent, useCallback } from "react";
import { useLocation } from "wouter";

export const useRouteToAnchor = () => {
  const [, setLocation] = useLocation();

  return useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setLocation(event.currentTarget.href);
    },
    [setLocation]
  );
};
