import Dexie, { Table } from "dexie";
import { MusicVideo, Note, Scene, Shot } from "../types";

export class Database extends Dexie {
  videos!: Table<MusicVideo, number>;
  scenes!: Table<Scene, number>;
  shots!: Table<Shot, number>;
  notes!: Table<Note, number>;

  palette!: Table<Shot, number>;

  constructor() {
    super("tempo-shot");
    this.version(6).stores({
      videos: "++id",
      scenes: "++id, musicVideoId",
      shots: "++id, musicVideoId",
      notes: "++id, musicVideoId",
      palette: "++id, musicVideoId",
    });
  }

  deleteScene(sceneId: number) {
    return this.transaction("rw", this.scenes, async () => {
      await this.scenes.delete(sceneId);
    });
  }

  deleteShot(shotId: number) {
    return this.transaction("rw", this.shots, async () => {
      await this.shots.delete(shotId);
    });
  }

  deleteNote(noteId: number) {
    return this.transaction("rw", this.notes, async () => {
      await this.notes.delete(noteId);
    });
  }

  deleteMusicVideo(musicVideoId: number) {
    return this.transaction(
      "rw",
      this.scenes,
      this.shots,
      this.notes,
      async () => {
        await this.scenes.where({ musicVideoId }).delete();
        await this.shots.where({ musicVideoId }).delete();
        await this.notes.where({ musicVideoId }).delete();

        this.videos.delete(musicVideoId);
      }
    );
  }

  resetDatabase() {
    return db.transaction(
      "rw",
      this.videos,
      this.scenes,
      this.shots,
      this.notes,
      async () => {
        await Promise.all(db.tables.map((table) => table.clear()));
      }
    );
  }
}

export const db = new Database();

// @ts-ignore
global.db = db;
