import { ChangeEvent, useCallback, useState } from "react";

type OnChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void | Promise<void>;

type InputState = [string, OnChange];

export const useInputState = (initialValue: string): InputState => {
  const [value, setValue] = useState(initialValue);

  const onChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
    },
    []
  );

  return [value, onChangeValue];
};
