import { FC, Fragment, useCallback, useState } from "react";
import { Modal, ModalBody, ModalBodyRenderProps } from "./Modal";
import { NewNoteForm } from "./NewNoteForm";
import { useHotkeys } from "react-hotkeys-hook";
import { ADD_NOTE } from "../hotkeys";

export const NewNoteController: FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const renderModalBody = useCallback(
    ({ closeModal }: ModalBodyRenderProps) => (
      <ModalBody>
        <NewNoteForm onCreated={closeModal} />
      </ModalBody>
    ),
    []
  );

  useHotkeys(ADD_NOTE, (event: KeyboardEvent) => {
    event.preventDefault();
    setIsEditing(true);
  });

  return (
    <Fragment>
      <button onClick={onEdit}>Add note</button>
      <Modal isOpen={isEditing} title="Add note" onClose={onCancelEdit}>
        {renderModalBody}
      </Modal>
    </Fragment>
  );
};
