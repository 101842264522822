import { FC } from "react";
import { SceneForm } from "./SceneForm";
import { Scene } from "../types";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";

interface Props {
  sceneId: number;
  onUpdate?: () => void;
}

export const EditSceneForm: FC<Props> = ({ sceneId, onUpdate }) => {
  const onSubmit = async (scene: Scene) => {
    await db.scenes.update(sceneId, { ...scene });

    if (onUpdate) {
      onUpdate();
    }
  };

  const scene = useLiveQuery(() => db.scenes.get(sceneId));

  if (!scene) {
    return null;
  }

  return (
    <SceneForm scene={scene} submitLabel="Update Scene" onSubmit={onSubmit} />
  );
};
