import { css } from "@emotion/react";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Region, WaveForm, WaveSurfer } from "wavesurfer-react";
import { default as WaveSurferInstance } from "wavesurfer.js";

// @ts-expect-error
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
// @ts-expect-error
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
import { eventLoop } from "../utils/eventLoop";
import { TimeCodeDisplay } from "./TimeCodeDisplay";
import { useAtom } from "jotai";
import { currentTimeState } from "../state/currentTimeState";
import colors from "../styles/colors";
import Icon from "@mdi/react";
import {
  mdiFastForward10,
  mdiFastForward5,
  mdiPageFirst,
  mdiPause,
  mdiPlay,
  mdiRewind10,
  mdiRewind5,
} from "@mdi/js";
import { useShotListItemsAsRegions } from "../hooks/useShotListItemsAsRegions";
import { useHotkeys } from "react-hotkeys-hook";
import {
  FAST_FORWARD_10,
  FAST_FORWARD_5,
  PLAY_PAUSE,
  REWIND_10,
  REWIND_5,
} from "../hotkeys";
import { useTransportListener } from "./TransportContext";
import { RegionDataPayload, RegionUpdateEndEvent } from "../wavesurfer/types";
import { db } from "../db";
import { isUndefined } from "lodash";
import { TooltipWrapper } from "./Tooltip";
import { Spinner } from "./Spinner";
import { flexboxCss } from "../styles/layout";

interface Props {
  projectId: number;
  audio: Blob | undefined;
}

const containerCss = css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  padding: 10px;
`;

const loadingOverlayCss = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const controlsLayoutCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const AudioDisplay: FC<Props> = ({ projectId, audio }) => {
  const wavesurferRef = useRef<WaveSurferInstance | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [state, setState] = useState<"playing" | "paused">("paused");
  const [currentTime, setCurrentTime] = useAtom(currentTimeState);
  const onMount = useCallback(
    (waveSurfer: WaveSurferInstance | null) => {
      wavesurferRef.current = waveSurfer;
      if (wavesurferRef.current && audio) {
        setIsLoading(true);

        wavesurferRef.current.on("loading", (data) => {
          setLoaded(data as number);
        });

        wavesurferRef.current.on("ready", () => {
          setIsLoading(false);
          setLoaded(100);
        });

        wavesurferRef.current.on("seeking", (currentTime: number) => {
          console.log(`seeking`, currentTime);
        });

        wavesurferRef.current.on("drag", (currentTime: number) => {
          console.log(`drag`, currentTime);
        });

        wavesurferRef.current.on("play", () => {
          setState("playing");
        });

        wavesurferRef.current.on("pause", () => {
          setState("paused");
        });

        // Fires when the user clicks on the waveform
        wavesurferRef.current.on("interaction", async () => {
          await eventLoop();

          if (wavesurferRef.current) {
            setCurrentTime(wavesurferRef.current.getCurrentTime());
          }
        });

        // Fires continuously when playing
        wavesurferRef.current.on("audioprocess", (currentTime: number) => {
          setCurrentTime(currentTime);
        });

        wavesurferRef.current.loadBlob(audio);

        if (window) {
          // @ts-expect-error
          global.surfer = wavesurferRef.current;
        }
      }
    },
    [audio, setCurrentTime]
  );

  const plugins = useMemo(
    () =>
      [
        {
          plugin: RegionsPlugin,
          options: { dragSelection: false },
        },
        {
          plugin: TimelinePlugin,
          options: {
            container: "#timeline",
          },
        },
      ].filter(Boolean),
    []
  );

  const regions = useShotListItemsAsRegions(projectId);

  const onPlay = useCallback(() => {
    wavesurferRef.current?.playPause();
  }, []);

  const onRewind = useCallback(() => {
    wavesurferRef.current?.setCurrentTime(0);
  }, []);

  const onSeekToRegion = useCallback(
    (timestamp: number) => () => {
      wavesurferRef.current?.setCurrentTime(timestamp);
    },
    []
  );

  const onUpdateRegionEnd = useCallback(
    ({ id, type }: RegionDataPayload) =>
      async (event: RegionUpdateEndEvent) => {
        if (isUndefined(id)) {
          throw new Error(`Unable to update shot list without an ID`);
        }

        if (type === "scene") {
          await db.scenes.update(id, { timestamp: event.start });
        } else if (type === "shot") {
          await db.shots.update(id, { timestamp: event.start });
        }
      },
    []
  );

  const onRewind5 = useCallback(() => {
    const currentTime = wavesurferRef.current?.getCurrentTime();
    if (currentTime !== undefined) {
      wavesurferRef.current?.setCurrentTime(Math.max(currentTime - 5, 0));
    }
  }, []);

  const onRewind10 = useCallback(() => {
    const currentTime = wavesurferRef.current?.getCurrentTime();
    if (currentTime !== undefined) {
      wavesurferRef.current?.setCurrentTime(Math.max(currentTime - 10, 0));
    }
  }, []);

  const onFastForward5 = useCallback(() => {
    const currentTime = wavesurferRef.current?.getCurrentTime();
    const duration = wavesurferRef.current?.getDuration();
    if (currentTime !== undefined && duration !== undefined) {
      wavesurferRef.current?.setCurrentTime(
        Math.min(currentTime + 5, duration)
      );
    }
  }, []);

  const onFastForward10 = useCallback(() => {
    const currentTime = wavesurferRef.current?.getCurrentTime();
    const duration = wavesurferRef.current?.getDuration();
    if (currentTime !== undefined && duration !== undefined) {
      wavesurferRef.current?.setCurrentTime(
        Math.min(currentTime + 10, duration)
      );
    }
  }, []);

  const seekTo = useCallback((timecode: number) => {
    wavesurferRef.current?.setCurrentTime(timecode);
  }, []);
  useTransportListener("seekTo", seekTo);

  useHotkeys(
    PLAY_PAUSE,
    () => {
      wavesurferRef.current?.playPause();
    },
    { preventDefault: true }
  );

  useHotkeys(REWIND_10, onRewind10);
  useHotkeys(REWIND_5, onRewind5);
  useHotkeys(FAST_FORWARD_5, onFastForward5);
  useHotkeys(FAST_FORWARD_10, onFastForward10);

  if (!audio) {
    return (
      <div
        data-is-panel
        data-color={600}
        css={flexboxCss({ align: "center", height: "50px", justify: "center" })}
      >
        <em>No audio attached.</em>
      </div>
    );
  }

  const transportIcon = state === "playing" ? mdiPause : mdiPlay;
  const transportLabel = state === "playing" ? "Pause" : "Play";

  return (
    <div data-is-panel css={containerCss}>
      {isLoading && (
        <div css={loadingOverlayCss}>
          <Spinner reason={`Loading audio... ${loaded}%`} />
        </div>
      )}

      <WaveSurfer plugins={plugins} onMount={onMount}>
        <WaveForm
          responsive
          ignoreSilenceMode
          id={`wavesurfer-${projectId}`}
          cursorColor={colors.sky[500]}
          waveColor={colors.slate[400]}
          progressColor={colors.sky[700]}
        >
          {regions.map((regionProps) => (
            <Region
              key={regionProps.id}
              {...regionProps}
              onUpdateEnd={onUpdateRegionEnd(regionProps.data)}
              onClick={onSeekToRegion(regionProps.start)}
            />
          ))}
        </WaveForm>
        <div id="timeline" />
      </WaveSurfer>

      {!isLoading && (
        <div css={controlsLayoutCss}>
          <button type="button" onClick={onRewind}>
            <Icon path={mdiPageFirst} />
          </button>

          <TooltipWrapper
            content={`Rewind 10 seconds (${REWIND_10})`}
            position="south"
          >
            <button type="button" onClick={onRewind10}>
              <Icon path={mdiRewind10} />
            </button>
          </TooltipWrapper>

          <TooltipWrapper
            content={`Rewind 5 seconds (${REWIND_5})`}
            position="south"
          >
            <button type="button" onClick={onRewind5}>
              <Icon path={mdiRewind5} />
            </button>
          </TooltipWrapper>

          <TimeCodeDisplay timecode={currentTime} />

          <TooltipWrapper
            content={`${transportLabel} (${PLAY_PAUSE})`}
            position="south"
          >
            <button type="button" onClick={onPlay}>
              <Icon path={transportIcon} />
            </button>
          </TooltipWrapper>

          <TooltipWrapper
            content={`Fast-forward 5 seconds (${FAST_FORWARD_5})`}
            position="south"
          >
            <button type="button" onClick={onFastForward5}>
              <Icon path={mdiFastForward5} />
            </button>
          </TooltipWrapper>

          <TooltipWrapper
            content={`Fast-forward 10 seconds (${FAST_FORWARD_10})`}
            position="south"
          >
            <button type="button" onClick={onFastForward10}>
              <Icon path={mdiFastForward10} />
            </button>
          </TooltipWrapper>
        </div>
      )}
    </div>
  );
};
