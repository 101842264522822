import { FC, Fragment, useCallback, useState } from "react";
import { Modal, ModalBody, ModalBodyRenderProps } from "./Modal";
import { NewSceneForm } from "./NewSceneForm";

export const NewSceneController: FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const renderModalBody = useCallback(
    ({ closeModal }: ModalBodyRenderProps) => (
      <ModalBody>
        <NewSceneForm onCreated={closeModal} />
      </ModalBody>
    ),
    []
  );

  return (
    <Fragment>
      <button onClick={onEdit}>Add scene</button>
      <Modal isOpen={isEditing} title="Add scene" onClose={onCancelEdit}>
        {renderModalBody}
      </Modal>
    </Fragment>
  );
};
