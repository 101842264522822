import { createContext, FC, PropsWithChildren, useContext } from "react";

type ContextValue = number;

const Context = createContext<ContextValue>(NaN);

interface Props {
  projectId: number;
}

export const ActiveProject: FC<PropsWithChildren<Props>> = ({
  children,
  projectId,
}) => <Context.Provider value={projectId}>{children}</Context.Provider>;

export const useActiveProjectId = () => useContext(Context);
