import Peer from "peerjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { ClientState, OnSyncReceive, SyncClient } from "./types";
import { logger } from "./logger";
import { SetErrorState, useErrorHandler } from "./useErrorHandler";
import { isReadyMessage, isSyncMessage } from "./utils";

export const useSyncClient = (
  hostId: string,
  onSyncReceive: OnSyncReceive
): SyncClient => {
  const [state, setState] = useState<ClientState>("starting");
  const { error, onError } = useErrorHandler(setState as SetErrorState);

  const onSyncReceiveRef = useRef<OnSyncReceive>(onSyncReceive);
  if (onSyncReceiveRef.current !== onSyncReceive) {
    onSyncReceiveRef.current = onSyncReceive;
  }

  useEffect(() => {
    const peer = new Peer();

    try {
      logger.log(`Creating peering host`);

      peer
        .on("open", (id) => {
          logger.log("My peering ID: " + id);
          logger.log(`Connecting to peering host ${hostId}`);

          const conn = peer.connect(hostId, { reliable: true });

          conn
            .on("open", function () {
              logger.log(`Connected to peering host ${hostId}`);
              setState("waiting");
            })
            .on("error", function (err) {
              onError(`Failed to communicate with peering host ${hostId}`, err);
            })
            .on("data", async (data: unknown) => {
              logger.log(data);

              if (isReadyMessage(data)) {
                setState("receiving");
              }

              if (isSyncMessage(data)) {
                setState("processing");

                const blob = new Blob([data.blob]);

                logger.log(`Importing database ${blob.size} bytes`);

                try {
                  await onSyncReceiveRef.current(blob);
                } catch (err) {
                  onError(`Database import failed`, err);
                } finally {
                  conn.close();
                }

                setState("done");
              }
            });
        })
        .on("error", (err) => {
          onError(`Failed to open peering host`, err);
        });
    } catch (err) {
      onError(`Failed to peer with host ${hostId}`, err);
    }

    return () => {
      try {
        if (peer) {
          peer.on("disconnected", () => {
            peer.destroy();
          });

          peer.disconnect();
        }
      } catch (err) {
        onError(`Failed to close peering client`, err);
      }
    };
  }, [hostId, onError]);

  return useMemo(() => ({ state, error }), [state, error]) as SyncClient;
};
