import { FC } from "react";
import { css } from "@emotion/react";
import { isEmpty } from "lodash";
import { ShotListItemDisplay } from "./ShotListItemDisplay";
import { useShotListItems } from "../hooks/useShotListItems";

interface Props {
  musicVideoId: number;
}

const containerCss = css`
  display: grid;
  grid-template-columns: min-content min-content min-content 10fr min-content;
  align-items: stretch;
  justify-content: start;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding: 0 15px;
  max-height: 100%;
  overflow: auto;
  flex: 0 1 auto;
`;

export const ShotList: FC<Props> = ({ musicVideoId }) => {
  const items = useShotListItems(musicVideoId);

  if (isEmpty(items)) {
    return <div css={containerCss}>No scenes/shots</div>;
  }

  return (
    <div css={containerCss}>
      {items.map((item) => (
        <ShotListItemDisplay key={`${item.type}-${item.id}`} {...item} />
      ))}
    </div>
  );
};
