export const SHOT_TYPES = [
  "Establishing",
  "Master shot",
  "Wide shot",
  "Full shot",
  "Cowboy shot",
  "Medium shot",
  "Medium close-up shot",
  "Close-up shot",
  "Extreme close-up shot",
  "Insert shot",
];

export const SHOT_SIZES = [
  "Single",
  "Double",
  "Multiple",
  "Crowd",
  "Over the shoulder",
  "Point of view",
  "Insert",
];

export const SHOT_ANGLES = [
  "Low angle",
  "High angle",
  "Extreme high angle",
  "Overhead",
  "Dutch angle (rotated)",
  "Eye level",
  "Shoulder level",
  "Hip level",
  "Knee level",
  "Ground level",
];

export const SHOT_FOCUS = [
  "Deep focus",
  "Shallow focus",
  "Soft focus",
  "Rack focus",
];

export const EQUIPMENT = [
  "Handheld",
  "Tripod",
  "Pedestal",
  "Crane / Jib",
  "Overhead rig",
  "Dolly / Slider",
  "Stabilizer",
  "Drone",
];

export const MOVEMENT = [
  "Static",
  "Pan",
  "Whip pan",
  "Tilt",
  "Push in",
  "Pull out",
  "Zoom",
  "Crash zoom",
  "Dolly zoom",
  "Camera roll",
  "Tracking shot",
  "Trucking shot",
  "Arc shot",
  "Boom shot",
  "Random / shake",
];
