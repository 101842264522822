import { css } from "@emotion/react";
import { FC, KeyboardEvent, PropsWithChildren, useCallback } from "react";
import colors from "../styles/colors";
import { shadow } from "../styles/globals";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { withAlpha } from "../styles/utils";

interface ChildrenProps {
  closeModal: () => void;
}

export type ModalBodyRenderProps = ChildrenProps;

interface Props {
  children: (childProps: ChildrenProps) => React.ReactElement;
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

export const modalPositionerCss = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${withAlpha(colors.slate[50], 0.9)};
`;

export const modalCss = css`
  position: relative;
  border-radius: 5px;
  background-color: ${colors.slate[50]};
  ${shadow};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 80vh;
`;

export const modalButtonsCss = css`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 10px;
`;

export const modalHeaderContainerCss = css`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

const modalTitleCss = css`
  margin-left: 5px;
`;

export const modalBodyCss = css`
  padding: 20px;
  overflow: auto;

  min-width: 460px;
  min-height: 240px;
`;

export const ModalBody: FC<PropsWithChildren> = ({ children }) => (
  <div css={modalBodyCss}>{children}</div>
);

const modalFooterCss = css`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

export const ModalFooter: FC<PropsWithChildren> = ({ children }) => (
  <div css={modalFooterCss}>{children}</div>
);

interface ModalHeaderProps {
  onClose: () => void;
  title: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({ title, onClose }) => (
  <div css={modalHeaderContainerCss}>
    <strong css={modalTitleCss}>{title}</strong>
    <button type="button" onClick={onClose}>
      <Icon path={mdiClose} />
    </button>
  </div>
);

export const Modal: FC<Props> = ({ children, isOpen, title, onClose }) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  return isOpen ? (
    <div css={modalPositionerCss} onKeyDown={onKeyDown}>
      <div css={modalCss}>
        <ModalHeader title={title} onClose={onClose} />
        {children({ closeModal: onClose })}
      </div>
    </div>
  ) : null;
};
