import { useMemo } from "react";
import { useShotListItems } from "./useShotListItems";
import { RegionParams } from "wavesurfer.js/src/plugin/regions";
import colors from "../styles/colors";
import { RegionDataPayload } from "../wavesurfer/types";
import { withAlpha } from "../styles/utils";

export type RegionData = RegionParams & {
  id: string;
  start: number;
  data: RegionDataPayload;
};

export const useShotListItemsAsRegions = (
  musicVideoId: number
): RegionData[] => {
  const items = useShotListItems(musicVideoId);

  return useMemo(
    () =>
      items
        .filter((item) => item.type !== "note")
        .map((item) => ({
          id: `${item.type}-${item.id}`,
          drag: item.type === "shot",
          resize: false,
          start: item.timestamp,
          end: item.timestamp + (item.type === "scene" ? 0.2 : 0.1),
          color:
            item.type === "scene"
              ? withAlpha(colors.slate[900], 0.5)
              : colors.slate[400],
          data: {
            id: item.id,
            type: item.type,
          },
        })),
    [items]
  );
};
