import { FC } from "react";
import { useSyncClient } from "../sync";
import { useRouteToAnchor } from "../routes";
import { Spinner } from "./Spinner";
import { importInto } from "dexie-export-import";
import { db } from "../db";

interface Props {
  hostId: string;
}

const onImportDatabase = async (blob: Blob) => {
  await importInto(db, blob, {
    chunkSizeBytes: 1024 * 1024 * 256,
    overwriteValues: true,
  });
};

export const SyncClientController: FC<Props> = ({ hostId }) => {
  const { state, error } = useSyncClient(hostId, onImportDatabase);

  const routeToAnchor = useRouteToAnchor();

  if (state === "done") {
    return (
      <div>
        All project data has been sync'ed.{" "}
        <a href="/" onClick={routeToAnchor}>
          View projects
        </a>
      </div>
    );
  }

  if (state === "error") {
    return (
      <div>
        <pre>{error.toString()}</pre>
      </div>
    );
  }

  return <Spinner reason={state} />;
};
