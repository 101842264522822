import { css } from "@emotion/react";
import { FC, ReactNode } from "react";
import colors from "../styles/colors";
import { isFunction } from "lodash";
import { transition } from "../styles/transition";
import { useHoverState } from "../hooks/useHoverState";
import { useFocusState } from "../hooks/useFocusState";

type TooltipPosition = "north" | "south" | "east" | "west";

interface TooltipProps {
  content: ReactNode;
  position?: TooltipPosition;
  isVisible: boolean;
}

const OFFSET = "3px";

const tooltipCss = (position: TooltipPosition = "west", isVisible: boolean) => [
  css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: ${isVisible ? 1 : 0};
    transition: ${transition("opacity")};
  `,
  position === "north" &&
    css`
      bottom: ${OFFSET};
      margin-bottom: 100%;
      width: 100%;
    `,
  position === "south" &&
    css`
      top: ${OFFSET};
      margin-top: 100%;
      width: 100%;
    `,
  position === "west" &&
    css`
      right: ${OFFSET};
      margin-right: 100%;
      height: 100%;
    `,
  position === "east" &&
    css`
      left: ${OFFSET};
      margin-left: 100%;
      height: 100%;
    `,
];

const tooltipContentCss = css`
  font-size: 12px;
  font-weight: bold;
  background-color: ${colors.slate[700]};
  color: ${colors.slate[50]};
  border-radius: 5px;
  padding: 5px 7px;
  white-space: nowrap;
  z-index: 100;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.25);
`;

export const Tooltip: FC<TooltipProps> = ({ content, position, isVisible }) => (
  <div css={tooltipCss(position, isVisible)}>
    <div css={tooltipContentCss}>{content}</div>
  </div>
);

interface TooltipWrapperChildAttrs {}

interface TooltipWrapperProps {
  children: ((childAttrs: TooltipWrapperChildAttrs) => ReactNode) | ReactNode;
  content: ReactNode;
  position?: TooltipPosition;
}

const tooltipContainerCss = css`
  position: relative;
`;

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  content,
  position,
}) => {
  const { isHover, onMouseEnter, onMouseLeave } = useHoverState();
  const { hasFocus, onFocus, onBlur } = useFocusState();

  return (
    <div
      css={tooltipContainerCss}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Tooltip
        content={content}
        position={position}
        isVisible={isHover || hasFocus}
      />
      {isFunction(children) ? children({}) : children}
    </div>
  );
};
