import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { Shot } from "../types";
import { useAtomValue } from "jotai";
import { currentTimeState } from "../state/currentTimeState";
import { Datalist, Field } from "./Form";
import { formatTimecode } from "../utils/formatTimecode";
import { isUndefined } from "lodash";
import { parseTimecode } from "../utils/parseTimecode";
import { useInputState } from "../hooks/useInputState";
import {
  EQUIPMENT,
  MOVEMENT,
  SHOT_ANGLES,
  SHOT_FOCUS,
  SHOT_SIZES,
  SHOT_TYPES,
} from "../constants";

interface Props {
  header?: string;
  shot?: Partial<Shot>;
  submitLabel: string;
  onSubmit: (shot: Shot) => void | Promise<void>;
}

const DEFAULT_VALUE: Partial<Shot> = {
  title: "",
  description: "",
};

export const ShotForm: FC<Props> = ({
  header,
  shot = DEFAULT_VALUE,
  submitLabel,
  onSubmit,
}) => {
  const titleRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  const [title, onChangeTitle] = useInputState(shot.title ?? "");
  const [description, onChangeDescription] = useInputState(
    shot.description ?? ""
  );

  const currentTime = useAtomValue(currentTimeState);
  const [timestamp, setTimestamp] = useState(
    formatTimecode(isUndefined(shot.timestamp) ? currentTime : shot.timestamp)
  );
  const onChangeTimestamp = (event: ChangeEvent<HTMLInputElement>) => {
    setTimestamp(event.target.value);
  };

  useEffect(() => {
    if (isUndefined(shot.timestamp)) {
      setTimestamp(formatTimecode(currentTime));
    }
  }, [shot.timestamp, currentTime]);

  const [subject, onChangeSubject] = useInputState(shot.subject ?? "");
  const [shotSize, onChangeShotSize] = useInputState(shot.shotSize ?? "");
  const [shotType, onChangeShotType] = useInputState(shot.shotType ?? "");
  const [shotAngle, onChangeShotAngle] = useInputState(shot.shotAngle ?? "");
  const [shotFocus, onChangeShotFocus] = useInputState(shot.shotFocus ?? "");
  const [equipment, onChangeEquipment] = useInputState(shot.equipment ?? "");
  const [movement, onChangeMovement] = useInputState(shot.movement ?? "");

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      ...shot,
      title,
      description,
      subject,
      shotSize,
      shotType,
      shotAngle,
      shotFocus,
      equipment,
      movement,
      timestamp: parseTimecode(timestamp),
    });
  };

  return (
    <form onSubmit={onFormSubmit}>
      {header && <legend>{header}</legend>}
      <Field>
        <label htmlFor="timestamp">Timestamp</label>
        <input
          readOnly={isUndefined(shot.timestamp)}
          type="text"
          name="timestamp"
          autoComplete="off"
          value={timestamp}
          onChange={onChangeTimestamp}
        />
      </Field>

      <Field>
        <label htmlFor="title">Title</label>
        <input
          ref={titleRef}
          type="text"
          name="title"
          autoComplete="off"
          value={title}
          onChange={onChangeTitle}
        />
      </Field>

      <Field>
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          value={description}
          onChange={onChangeDescription}
        />
      </Field>

      <Field>
        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          name="subject"
          autoComplete="off"
          value={subject}
          onChange={onChangeSubject}
        />
      </Field>

      <Field>
        <label htmlFor="shot-size">Shot size</label>
        <input
          type="text"
          name="shot-size"
          list="shot-sizes"
          value={shotSize}
          onChange={onChangeShotSize}
        />
        <Datalist id="shot-sizes" options={SHOT_SIZES} />
      </Field>

      <Field>
        <label htmlFor="shot-type">Shot type</label>
        <input
          type="text"
          name="shot-type"
          list="shot-types"
          value={shotType}
          onChange={onChangeShotType}
        />
        <Datalist id="shot-types" options={SHOT_TYPES} />
      </Field>

      <Field>
        <label htmlFor="shot-angle">Shot angle</label>
        <input
          type="text"
          name="shot-angle"
          list="shot-angles"
          value={shotAngle}
          onChange={onChangeShotAngle}
        />
        <Datalist id="shot-angles" options={SHOT_ANGLES} />
      </Field>

      <Field>
        <label htmlFor="shot-focus">Shot focus</label>
        <input
          type="text"
          name="shot-focus"
          list="shot-focuses"
          value={shotFocus}
          onChange={onChangeShotFocus}
        />
        <Datalist id="shot-focuses" options={SHOT_FOCUS} />
      </Field>

      <Field>
        <label htmlFor="equipment">Equipment</label>
        <input
          type="text"
          name="equipment"
          list="equipments"
          value={equipment}
          onChange={onChangeEquipment}
        />
        <Datalist id="equipments" options={EQUIPMENT} />
      </Field>

      <Field>
        <label htmlFor="movement">Movement</label>
        <input
          type="text"
          name="movement"
          list="movements"
          value={movement}
          onChange={onChangeMovement}
        />
        <Datalist id="movements" options={MOVEMENT} />
      </Field>

      <div data-form-actions>
        <button type="submit">{submitLabel}</button>
      </div>
    </form>
  );
};
