import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { Note, Scene, Shot } from "../types";
import { css } from "@emotion/react";
import { TimeCodeDisplay } from "./TimeCodeDisplay";
import { db } from "../db";
import Icon from "@mdi/react";
import {
  mdiCameraBurst,
  mdiCameraIris,
  mdiFolderArrowRight,
  mdiNote,
  mdiPencil,
  mdiTrashCan,
} from "@mdi/js";
import { Modal, ModalBody, ModalBodyRenderProps } from "./Modal";
import { EditSceneForm } from "./EditSceneForm";
import { EditShotForm } from "./EditShotForm";
import colors from "../styles/colors";
import { useTransportActions } from "./TransportContext";
import { ShotListItemDetails } from "./ShotListItemDetails";
import { TooltipWrapper } from "./Tooltip";
import { isNumber, omit } from "lodash";
import { useHoverState } from "../hooks/useHoverState";
import { transition } from "../styles/transition";
import { EditNoteForm } from "./EditNoteForm";

export type ShotListItem =
  | (Scene & { type: "scene" })
  | (Shot & { type: "shot" })
  | (Note & { type: "note" });

type Props = ShotListItem;

const checkboxCss = css`
  align-self: start;
`;

const typeCss = css`
  text-transform: lowercase;
  font-size: 80%;
  padding-top: 2px;
  align-self: start;
`;

const actionsLayoutCss = (isVisible: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  opacity: ${isVisible ? 1 : 0};
  transition: ${transition("opacity")};
`;

export const ShotListItemDisplay: FC<Props> = (props) => {
  const { id, timestamp, description, type } = props;

  const { seekTo } = useTransportActions();
  const onSeekTo = useCallback(() => {
    seekTo(timestamp);
  }, [seekTo, timestamp]);

  const onDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const hasConfirmed = confirm(
      `Are you sure you want to delete this ${type}`
    );

    if (id && hasConfirmed) {
      if (type === "scene") {
        db.scenes.delete(id);
      }

      if (type === "shot") {
        db.shots.delete(id);
      }

      if (type === "note") {
        db.notes.delete(id);
      }
    }
  }, [id, type]);

  const isDone = "done" in props ? props.done : false;
  const onChangeDone = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (type === "shot" && isNumber(id)) {
        db.shots.update(id, { done: event.target.checked });
      }
    },
    [id, type]
  );

  const [isEditing, setIsEditing] = useState(false);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const renderEditModalBody = useCallback(
    ({ closeModal }: ModalBodyRenderProps) => {
      if (type === "scene") {
        return (
          <ModalBody>
            <EditSceneForm sceneId={id!} onUpdate={closeModal} />
          </ModalBody>
        );
      }

      if (type === "shot") {
        return (
          <ModalBody>
            <EditShotForm shotId={id!} onUpdate={closeModal} />
          </ModalBody>
        );
      }

      return (
        <ModalBody>
          <EditNoteForm noteId={id!} onUpdate={closeModal} />
        </ModalBody>
      );
    },
    [id, type]
  );

  const onMoveToPalette = async () => {
    await db.palette.add(omit(props, "id"));
  };

  const itemIcon =
    type === "scene"
      ? mdiCameraBurst
      : type === "shot"
      ? mdiCameraIris
      : mdiNote;

  const { isHover, ...hoverHandlers } = useHoverState();

  return (
    <Fragment>
      {type === "shot" ? (
        <input
          type="checkbox"
          css={checkboxCss}
          checked={isDone}
          onChange={onChangeDone}
        />
      ) : (
        <div />
      )}

      <a href="#edit" onClick={onSeekTo} {...hoverHandlers}>
        <TimeCodeDisplay timecode={timestamp} />
      </a>

      <div {...hoverHandlers}>
        <div css={typeCss}>
          <Icon path={itemIcon} size={0.5} color={colors.slate[600]} />
        </div>
      </div>

      <div {...hoverHandlers}>
        <ShotListItemDetails {...props} />
        {description
          ?.split("\n")
          .filter(Boolean)
          .map((line, index) => (
            <p key={`line-${index}`} data-color="500">
              <small>{line}</small>
            </p>
          ))}
      </div>

      <div css={actionsLayoutCss(isHover)} {...hoverHandlers}>
        <TooltipWrapper position="west" content={`Edit ${type}`}>
          <button onClick={onEdit}>
            <Icon path={mdiPencil} />
          </button>
        </TooltipWrapper>

        <TooltipWrapper position="west" content={`Delete ${type}`}>
          <button onClick={onDelete}>
            <Icon path={mdiTrashCan} />
          </button>
        </TooltipWrapper>

        {type === "shot" && (
          <TooltipWrapper position="west" content={`Move to palette`}>
            <button onClick={onMoveToPalette}>
              <Icon path={mdiFolderArrowRight} />
            </button>
          </TooltipWrapper>
        )}

        <Modal isOpen={isEditing} title={`Edit ${type}`} onClose={onCancelEdit}>
          {renderEditModalBody}
        </Modal>
      </div>
    </Fragment>
  );
};
