import { FC } from "react";
import { ShotForm } from "./ShotForm";
import { Shot } from "../types";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";

interface Props {
  shotId: number;
  onUpdate?: () => void;
}

export const EditShotForm: FC<Props> = ({ shotId, onUpdate }) => {
  const onSubmit = async (shot: Shot) => {
    await db.shots.update(shotId, { ...shot });

    if (onUpdate) {
      onUpdate();
    }
  };

  const shot = useLiveQuery(() => db.shots.get(shotId));

  if (!shot) {
    return null;
  }

  return <ShotForm shot={shot} submitLabel="Update Shot" onSubmit={onSubmit} />;
};
