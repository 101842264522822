import { FC } from "react";
import { MusicVideoForm } from "./MusicVideoForm";
import { MusicVideo } from "../types";
import { db } from "../db";

export const NewMusicVideoForm: FC = () => {
  const onCreate = async (video: MusicVideo) => {
    await db.videos.add(video);
  };

  return (
    <MusicVideoForm
      header="Create a new project"
      submitLabel="Create project"
      onSubmit={onCreate}
    />
  );
};
