import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import colors from "../styles/colors";

const fieldCss = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: stretch;
  width: 100%;

  & > label {
    font-weight: bold;
    font-size: 80%;
    color: ${colors.stone[800]};
    z-index: 1;
  }

  & > textarea {
    resize: vertical;
  }
`;

export const Field: FC<PropsWithChildren> = ({ children }) => (
  <div css={fieldCss}>{children}</div>
);

interface DatalistProps {
  id: string;
  options: string[];
}

export const Datalist: FC<DatalistProps> = ({ id, options }) => (
  <datalist id={id}>
    {options.map((option, index) => (
      <option key={`${id}-${index}`} value={option} />
    ))}
  </datalist>
);
