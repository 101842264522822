import { padEnd, padStart } from "lodash";

export const formatTimecode = (timecode: number) => {
  const minutes = Math.floor(timecode / 60);
  const seconds = Math.floor(timecode % 60);
  const secondsFormatted = padStart(seconds.toFixed(0), 2, "0");
  const fractions = Math.max(0, (timecode % 1) * 100 - 1);
  const fractionsFormatted = padEnd(fractions.toFixed(0), 2, "0");

  return `${minutes}:${secondsFormatted}:${fractionsFormatted}`;
};
