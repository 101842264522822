import { useCallback, useMemo, useState } from "react";
import { logger } from "./logger";

export type SetErrorState = (state: string) => void;

export const useErrorHandler = (setState: SetErrorState) => {
  const [error, setError] = useState<Error | unknown>();

  const onError = useCallback(
    (message: string, err: Error | unknown) => {
      logger.error(message, err);
      setError(err);
      setState("error");
    },
    [setState]
  );

  return useMemo(() => ({ error, onError }), [error, onError]);
};
