import { FC } from "react";
import { ShotForm } from "./ShotForm";
import { Shot } from "../types";
import { db } from "../db";
import { useActiveProjectId } from "./ActiveProjectContext";

interface Props {
  onCreated?: () => void;
}

export const NewShotForm: FC<Props> = ({ onCreated }) => {
  const musicVideoId = useActiveProjectId();
  const onCreate = async (shot: Shot) => {
    await db.shots.add({ ...shot, musicVideoId });

    if (onCreated) {
      onCreated();
    }
  };

  return <ShotForm submitLabel="Add Shot" onSubmit={onCreate} />;
};
