import { Route } from "wouter";
import { NewMusicVideoForm } from "../components/NewMusicVideoForm";
import { MusicVideosList } from "../components/MusicVideosList";
import { css } from "@emotion/react";
import { TopNav } from "../components/TopNav";
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useRef,
  useState,
} from "react";
import { exportDB, importInto } from "dexie-export-import";
import { saveAs } from "file-saver";
import { db } from "../db";
import {
  mdiCloudDownloadOutline,
  mdiCloudSyncOutline,
  mdiProgressUpload,
} from "@mdi/js";
import Icon from "@mdi/react";
import { getCurrentDate } from "../utils/getCurrentDate";
import { flexboxCss } from "../styles/layout";
import { TooltipWrapper } from "../components/Tooltip";
import { useProgressMonitor } from "../state/busyState";
import { Modal, ModalBody } from "../components/Modal";
import { SyncHostController } from "../components/SyncHostController";

const containerCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  padding: 10px;
`;

const ExportAction: FC = () => {
  const { start, finish } = useProgressMonitor();

  const onExportDatabase = useCallback(async () => {
    start("Exporting projects");

    try {
      const blob = await exportDB(db, {});

      saveAs(blob, `tempo-sync-projects.${getCurrentDate()}.json`);
    } finally {
      finish();
    }
  }, [finish, start]);

  return (
    <TooltipWrapper position="west" content="Export projects">
      <button onClick={onExportDatabase}>
        <Icon path={mdiCloudDownloadOutline} />
      </button>
    </TooltipWrapper>
  );
};

const ImportAction: FC = () => {
  const { start, finish } = useProgressMonitor();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onImportDatabase = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      start("Importing projects");

      const reader = new FileReader();

      reader.onload = async () => {
        if (reader.result) {
          try {
            const blob = new Blob([reader.result], { type: file.type });

            console.log(`Successfully read for importing`, blob.size);

            await importInto(db, blob, {
              chunkSizeBytes: 1024 * 1024 * 256,
              overwriteValues: true,
            });
          } finally {
            finish();
          }
        }
      };

      reader.onerror = (error) => {
        finish();

        console.error(error);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Fragment>
      <TooltipWrapper position="west" content="Import projects">
        <button onClick={onImportDatabase}>
          <Icon path={mdiProgressUpload} />
        </button>
      </TooltipWrapper>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Fragment>
  );
};

const SyncAction: FC = () => {
  const [isSharing, setIsSharing] = useState(false);
  const onSyncProjects = useCallback(() => {
    setIsSharing(true);
  }, []);

  const onCloseSharingModal = useCallback(() => {
    setIsSharing(false);
  }, []);

  return (
    <Fragment>
      <TooltipWrapper position="west" content="Sync with mobile">
        <button onClick={onSyncProjects}>
          <Icon path={mdiCloudSyncOutline} />
        </button>
      </TooltipWrapper>

      <Modal
        isOpen={isSharing}
        title="Sync projects"
        onClose={onCloseSharingModal}
      >
        {() => (
          <ModalBody>
            <SyncHostController />
          </ModalBody>
        )}
      </Modal>
    </Fragment>
  );
};

export const ProjectsRoute = () => (
  <Route path="/">
    {() => (
      <div>
        <TopNav>
          <div css={flexboxCss({ align: "center", gap: "5px", width: "auto" })}>
            <SyncAction />
            <ImportAction />
            <ExportAction />
          </div>
        </TopNav>
        <div css={containerCss}>
          <NewMusicVideoForm />
          <MusicVideosList />
        </div>
      </div>
    )}
  </Route>
);
