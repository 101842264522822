import { FC } from "react";
import { flexboxCss } from "../styles/layout";
import { css, keyframes } from "@emotion/react";
import colors from "../styles/colors";
import { capitalize } from "lodash";

interface Props {
  reason?: string;
}

const ringKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loaderCss = css`
  display: inline-block;
  width: 24px;
  height: 24px;

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid ${colors.slate[800]};
    border-color: ${colors.slate[800]} transparent ${colors.slate[800]}
      transparent;
    animation: ${ringKeyframes} 1s linear infinite;
  }
`;

export const Spinner: FC<Props> = ({ reason }) => {
  if (reason) {
    return (
      <div
        css={flexboxCss({ align: "center", justify: "center", gap: "10px" })}
      >
        <div css={loaderCss} />
        <strong data-color="700">{capitalize(reason)}</strong>
      </div>
    );
  }

  return <div css={loaderCss} />;
};
