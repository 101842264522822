import { atom, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

export const busyState = atom<string | undefined>(undefined);

export const useProgressMonitor = () => {
  const setBusy = useSetAtom(busyState);

  const start = useCallback(
    (reason: string) => {
      setBusy(reason);
    },
    [setBusy]
  );

  const finish = useCallback(() => {
    setBusy(undefined);
  }, [setBusy]);

  const handlers = useMemo(() => ({ start, finish }), [finish, start]);

  // @ts-expect-error
  window.pm = handlers;

  return handlers;
};
