import { ChangeEvent, FC, FormEvent, useState } from "react";
import { MusicVideo } from "../types";
import { Field } from "./Form";

interface Props {
  header?: string;
  video?: MusicVideo;
  submitLabel: string;
  onSubmit: (video: MusicVideo) => void | Promise<void>;
}

const DEFAULT_VALUE: MusicVideo = {
  title: "",
  scenes: [],
  shots: [],
};

export const MusicVideoForm: FC<Props> = ({
  header,
  video = DEFAULT_VALUE,
  submitLabel,
  onSubmit,
}) => {
  const [title, setTitle] = useState(video.title);
  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const [audioBlob, setAudioBlob] = useState<Blob | undefined>(video.audio);

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const blob = new Blob([reader.result], { type: file.type });
          setAudioBlob(blob);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({ ...video, title, audio: audioBlob });
  };

  return (
    <form data-is-panel onSubmit={onFormSubmit}>
      {header && <legend>{header}</legend>}
      <Field>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          name="title"
          value={title}
          onChange={onChangeTitle}
        />
      </Field>

      <Field>
        <label htmlFor="audio-track">Audio</label>
        <input type="file" name="audio-track" onChange={onChangeFile} />
      </Field>

      <div data-form-actions>
        <button type="submit">{submitLabel}</button>
      </div>
    </form>
  );
};
