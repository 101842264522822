import { useCallback } from "react";
import { useLocation } from "wouter";

export const useRouteToPath = (path: string) => {
  const [, setLocation] = useLocation();

  return useCallback(() => {
    setLocation(path);
  }, [path, setLocation]);
};
