import { css } from "@emotion/react";
import { FC } from "react";
import { formatTimecode } from "../utils/formatTimecode";

interface Props {
  timecode: number;
}

const containerCss = css`
  font-family: monospace;
`;

export const TimeCodeDisplay: FC<Props> = ({ timecode }) => {
  return <div css={containerCss}>{formatTimecode(timecode)}</div>;
};
