import { FC } from "react";
import { NoteForm } from "./NoteForm";
import { Note } from "../types";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";

interface Props {
  noteId: number;
  onUpdate?: () => void;
}

export const EditNoteForm: FC<Props> = ({ noteId, onUpdate }) => {
  const onSubmit = async (note: Note) => {
    await db.notes.update(noteId, { ...note });

    if (onUpdate) {
      onUpdate();
    }
  };

  const note = useLiveQuery(() => db.notes.get(noteId));

  if (!note) {
    return null;
  }

  return <NoteForm note={note} submitLabel="Update Note" onSubmit={onSubmit} />;
};
