import { css } from "@emotion/react";
import colors from "./colors";
import { transition } from "./transition";
import { map } from "lodash";
import { withAlpha } from "./utils";

export const shadow = css`
  box-shadow: 5px 5px 13px 5px ${withAlpha(colors.slate[200], 0.7)},
    2px 2px 3px 1px ${withAlpha(colors.slate[400], 0.7)},
    -5px -5px 13px 5px ${withAlpha(colors.sky[50], 0.7)},
    -2px -2px 3px 1px ${withAlpha(colors.sky[400], 0.7)};
`;

const createTextColorRules = () =>
  map(
    colors.slate,
    (color, shade) => css`
      [data-color="${shade}"] {
        color: ${color};
      }
    `
  );

export const globalCss = css`
  body {
    background-color: ${colors.slate[50]};
    color: ${colors.slate[800]};

    /* Set the color of the scrollbar track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Set the color of the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.slate[500]};
    }

    /* Set the color of the scrollbar thumb on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${colors.slate[600]};
    }

    scrollbar-color: ${colors.slate[500]} transparent;
  }

  ::selection {
    background-color: ${colors.slate[600]};
    color: ${colors.slate[50]};
  }

  p {
    margin: 5px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  strong {
    color: ${colors.slate[800]};
  }

  code {
    color: ${colors.slate[800]};
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > legend {
      font-weight: bold;
      color: ${colors.slate[800]};
      padding: 0;
      margin: 0;
    }

    & [data-form-actions] {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
    }
  }

  &[data-is-panel] {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;

    ${shadow}
  }

  input,
  textarea {
    font-family: inherit;
    font-size: 90%;
    background-color: inherit;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${colors.slate[800]};

    transition: ${transition("box-shadow", "background-color")};

    &:focus {
      outline: none;
      ${shadow}
    }

    &[readonly] {
      background-color: ${colors.slate[100]};
      color: ${colors.slate[600]};
    }
  }

  textarea {
    min-height: 300px;
  }

  input[type="checkbox"] {
    padding: 0;
    margin: 0;
  }

  a {
    color: ${colors.slate[700]};
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    transition: ${transition("color")};

    &:hover {
      color: ${colors.sky[700]};
    }

    > strong {
      color: inherit;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
    box-sizing: border-box;

    padding: 5px 10px;
    border: 1px solid ${colors.slate[800]};
    border-radius: 5px;
    color: ${colors.slate[600]};
    background-color: transparent;
    font-weight: 900;
    font-size: 70%;
    text-transform: uppercase;
    cursor: pointer;

    transition: ${transition("box-shadow", "color", "background-color")};

    &:hover {
      color: ${colors.slate[800]};
      ${shadow}
    }

    &:focus {
      outline: none;
      color: ${colors.slate[800]};
      ${shadow}
    }

    svg {
      fill: currentColor;
    }
  }

  button > svg,
  a > svg {
    min-width: 14px;
    min-height: 14px;
  }

  [data-button-group] {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  ${createTextColorRules()}
`;
