import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { Note } from "../types";
import { useAtomValue } from "jotai";
import { currentTimeState } from "../state/currentTimeState";
import { Field } from "./Form";
import { formatTimecode } from "../utils/formatTimecode";
import { isUndefined } from "lodash";
import { parseTimecode } from "../utils/parseTimecode";
import { useInputState } from "../hooks/useInputState";

interface Props {
  header?: string;
  note?: Partial<Note>;
  submitLabel: string;
  onSubmit: (note: Note) => void | Promise<void>;
}

const DEFAULT_VALUE: Partial<Note> = {
  description: "",
};

export const NoteForm: FC<Props> = ({
  header,
  note = DEFAULT_VALUE,
  submitLabel,
  onSubmit,
}) => {
  const titleRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  const [description, onChangeDescription] = useInputState(
    note.description ?? ""
  );

  const currentTime = useAtomValue(currentTimeState);
  const [timestamp, setTimestamp] = useState(
    formatTimecode(isUndefined(note.timestamp) ? currentTime : note.timestamp)
  );
  const onChangeTimestamp = (event: ChangeEvent<HTMLInputElement>) => {
    setTimestamp(event.target.value);
  };

  useEffect(() => {
    if (isUndefined(note.timestamp)) {
      setTimestamp(formatTimecode(currentTime));
    }
  }, [note.timestamp, currentTime]);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      ...note,
      description,
      timestamp: parseTimecode(timestamp),
    });
  };

  return (
    <form onSubmit={onFormSubmit}>
      {header && <legend>{header}</legend>}
      <Field>
        <label htmlFor="timestamp">Timestamp</label>
        <input
          readOnly={isUndefined(note.timestamp)}
          type="text"
          name="timestamp"
          autoComplete="off"
          value={timestamp}
          onChange={onChangeTimestamp}
        />
      </Field>

      <Field>
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          value={description}
          onChange={onChangeDescription}
        />
      </Field>

      <div data-form-actions>
        <button type="submit">{submitLabel}</button>
      </div>
    </form>
  );
};
