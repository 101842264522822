import { createContext, useContext, useEffect } from "react";

export interface TransportActions {
  seekTo: (timestamp: number) => void;
}

class TransportController implements TransportActions {
  listeners: Partial<TransportActions>[] = [];

  seekTo = (timestamp: number) => {
    this.listeners.forEach((listener) => {
      if (listener.seekTo) {
        listener.seekTo(timestamp);
      }
    });
  };

  subscribe = (listener: Partial<TransportActions>) => {
    this.listeners = [...this.listeners, listener];

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };
}

const CONTROLLER = new TransportController();

const TransportContext = createContext<TransportActions>(CONTROLLER);

const TransportControllerContext =
  createContext<TransportController>(CONTROLLER);

export const useTransportActions = () => useContext(TransportContext);

type ListenerType = keyof TransportActions;

export const useTransportListener = <L extends ListenerType>(
  type: L,
  listener: TransportActions[L]
) => {
  const controller = useContext(TransportControllerContext);

  useEffect(
    () => controller.subscribe({ [type]: listener }),
    [type, controller, listener]
  );
};
