import Peer from "peerjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { HostState, OnSyncSerialize, SyncHost, SyncMessage } from "./types";
import { logger } from "./logger";
import { SetErrorState, useErrorHandler } from "./useErrorHandler";

export const useSyncHost = (onSyncSerialize: OnSyncSerialize): SyncHost => {
  const [state, setState] = useState<HostState>("starting");
  const [hostId, setHostId] = useState<string | undefined>();
  const { error, onError } = useErrorHandler(setState as SetErrorState);

  const onSyncSerializeRef = useRef<OnSyncSerialize>(onSyncSerialize);
  if (onSyncSerializeRef.current !== onSyncSerialize) {
    onSyncSerializeRef.current = onSyncSerialize;
  }

  useEffect(() => {
    const peer = new Peer();

    try {
      logger.log(`Creating peering host`);

      peer
        .on("open", (id) => {
          logger.log("My peer ID: " + id);
          setHostId(id);
          setState("ready");
        })
        .on("error", (err) => {
          onError(`Failed to open peering host`, err);
        })
        .on("connection", async (conn) => {
          const connectionId = conn.peer;
          logger.log("Incoming peering connection: " + connectionId);

          setState("serializing");
          const blob = await onSyncSerializeRef.current();

          logger.log(`Sending ${blob.size} bytes`);
          setState("sending");

          // Send the ready message
          conn.send({ type: "ready" });

          // Send the sync payload
          const message: SyncMessage = { type: "sync", blob };
          conn.send(message);

          conn.on("close", () => {
            logger.log("Connection closed: " + connectionId);
            setState("ready");
          });
        });
    } catch (err) {
      onError(`Failed to create peering host`, err);
    }

    return () => {
      try {
        if (peer) {
          peer.on("disconnected", () => {
            peer.destroy();
          });

          peer.disconnect();
        }
      } catch (err) {
        onError(`Failed to close peering host`, err);
      }
    };
  }, [onError]);

  return useMemo(
    () => ({ hostId, state, error }),
    [hostId, state, error]
  ) as SyncHost;
};
