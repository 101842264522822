import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { useRouteToPath } from "../routes";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiWaveform } from "@mdi/js";
import colors from "../styles/colors";

interface Props {
  hasHomeLink?: boolean;
}

const containerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
`;

const leftLayoutCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const logoCss = css`
  width: 36px;
  height: 36px;
`;

export const TopNav: FC<PropsWithChildren<Props>> = ({
  hasHomeLink,
  children,
}) => {
  const onRouteToHome = useRouteToPath("/");

  return (
    <div css={containerCss}>
      <div css={leftLayoutCss}>
        {hasHomeLink ? (
          <button onClick={onRouteToHome}>
            <Icon path={mdiArrowLeft} />
          </button>
        ) : (
          <div css={logoCss}>
            <Icon path={mdiWaveform} color={colors.slate[700]} />
          </div>
        )}
        <strong>Tempo Sync</strong>
      </div>
      {children}
    </div>
  );
};
