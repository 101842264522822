import { FC, Fragment } from "react";
import { ShotListItem } from "./ShotListItemDisplay";
import { Scene, Shot } from "../types";
import { compact } from "lodash";
import Icon from "@mdi/react";
import { mdiMapMarker, mdiStickerEmoji } from "@mdi/js";
import { css } from "@emotion/react";

type Props = ShotListItem;

const layoutCss = css`
  display: inline-flex;
  align-items: center;
  gap: 1px;
  margin-right: 10px;
`;

const titleCss = css`
  text-transform: uppercase;
  font-size: 80%;
  margin-bottom: 5px;
`;

const ShotDetails: FC<Shot> = ({
  title,
  subject,
  shotSize,
  shotType,
  shotAngle,
  shotFocus,
  equipment,
  movement,
}) => {
  const content = compact([
    subject,
    shotSize,
    shotType,
    shotAngle,
    shotFocus,
    equipment,
    movement,
  ]).join(", ");

  return (
    <Fragment>
      <div css={titleCss}>
        <strong data-color={500}>{title}</strong>
      </div>
      {content && <div css={layoutCss}>{content}</div>}
    </Fragment>
  );
};

const SceneDetails: FC<Scene> = ({ title, intent, location }) => (
  <Fragment>
    <div css={titleCss}>
      <strong data-color={800}>{title}</strong>
    </div>

    {location && (
      <small css={layoutCss}>
        <Icon path={mdiMapMarker} size={0.7} /> {location}
      </small>
    )}

    {intent && (
      <small css={layoutCss}>
        <Icon path={mdiStickerEmoji} size={0.7} /> {intent}
      </small>
    )}
  </Fragment>
);

export const ShotListItemDetails: FC<Props> = ({ type, ...details }) => {
  if (type === "scene") {
    return <SceneDetails {...details} />;
  }

  if (type === "shot") {
    return <ShotDetails {...details} />;
  }

  return <div>{details.description}</div>;
};
