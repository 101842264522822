import { FC } from "react";
import { SceneForm } from "./SceneForm";
import { Scene } from "../types";
import { db } from "../db";
import { useActiveProjectId } from "./ActiveProjectContext";

interface Props {
  onCreated?: () => void;
}

export const NewSceneForm: FC<Props> = ({ onCreated }) => {
  const musicVideoId = useActiveProjectId();
  const onCreate = async (scene: Scene) => {
    await db.scenes.add({ ...scene, musicVideoId });

    if (onCreated) {
      onCreated();
    }
  };

  return <SceneForm submitLabel="Add Scene" onSubmit={onCreate} />;
};
