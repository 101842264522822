import { useLiveQuery } from "dexie-react-hooks";
import { FC } from "react";
import { db } from "../db";
import { MusicVideo } from "../types";
import { useRouteToAnchor } from "../routes";
import { css } from "@emotion/react";
import { mdiMovieRoll, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import colors from "../styles/colors";
import { TooltipWrapper } from "./Tooltip";

interface MusicVideosListItemProps {
  video: MusicVideo;
}

const itemContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px;
`;

const itemTitleContainerCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MusicVideosListItem: FC<MusicVideosListItemProps> = ({ video }) => {
  const onDelete = async () => {
    if (video.id) {
      if (window.confirm("Are you sure you want to delete this project?")) {
        await db.videos.delete(video.id);
      }
    }
  };

  const routeTo = useRouteToAnchor();

  return (
    <div css={itemContainerCss}>
      <div css={itemTitleContainerCss}>
        <Icon path={mdiMovieRoll} color={colors.slate[700]} size={1} />

        <a href={`/project/${video.id}`} onClick={routeTo}>
          <strong>{video.title}</strong>
        </a>
      </div>

      <TooltipWrapper content="Delete project" position="west">
        <button onClick={onDelete}>
          <Icon path={mdiTrashCan} />
        </button>
      </TooltipWrapper>
    </div>
  );
};

const layoutCss = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MusicVideosList: FC = () => {
  const videos = useLiveQuery(() => db.videos.toArray());

  return (
    <div css={layoutCss}>
      {videos?.map((video) => (
        <MusicVideosListItem key={video.id} video={video} />
      ))}
    </div>
  );
};
