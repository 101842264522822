import { useCallback, useMemo, useState } from "react";

export const useFocusState = () => {
  const [hasFocus, setHasFocus] = useState(false);

  const onFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  return useMemo(
    () => ({
      hasFocus,
      onFocus,
      onBlur,
    }),
    [hasFocus, onFocus, onBlur]
  );
};
