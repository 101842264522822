import { FC, Fragment } from "react";
import useClipboard from "react-use-clipboard";
import { flexboxCss } from "../styles/layout";
import { QRCodeSVG } from "qrcode.react";
import colors from "../styles/colors";
import { useSyncHost } from "../sync";
import { Spinner } from "./Spinner";
import { db } from "../db";
import { exportDB } from "dexie-export-import";

const onSyncSerialize = async () => {
  const blob = await exportDB(db, {});
  return blob;
};

export const SyncHostController: FC = () => {
  const { hostId, state, error } = useSyncHost(onSyncSerialize);

  // eslint-disable-next-line no-restricted-globals
  const url = `${location.protocol}//${location.host}/webintent/sync/${hostId}`;

  const [isCopied, setCopied] = useClipboard(url, {
    successDuration: 5000,
  });

  const content =
    state === "starting" || state === "sending" ? (
      <Spinner reason={state} />
    ) : state === "serializing" ? (
      <Spinner reason="preparing" />
    ) : state === "error" ? (
      <pre>{error.toString()}</pre>
    ) : (
      <Fragment>
        <QRCodeSVG
          value={url}
          fgColor={colors.slate[700]}
          bgColor={colors.slate[50]}
        />
        <div>Scan the code with your phone or copy and share the link.</div>
        <div
          css={flexboxCss({
            align: "center",
            gap: "10px",
            direction: "column",
          })}
        >
          <button type="button" onClick={setCopied}>
            {isCopied ? "Link copied" : "Copy link to clipboard"}
          </button>
        </div>
      </Fragment>
    );

  return (
    <div
      css={flexboxCss({
        direction: "column",
        align: "center",
        justify: "center",
        gap: "10px",
        height: "100%",
      })}
    >
      {content}
    </div>
  );
};
