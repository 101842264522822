import { Global, css } from "@emotion/react";
import { ProjectsRoute } from "./routes/ProjectsRoute";
import { ProjectRoute } from "./routes/ProjectRoute";
import { globalCss } from "./styles/globals";
import { BusyOverlay } from "./components/BusyOverlay";
import { WebIntentSyncRoute } from "./routes/WebIntentSyncRoute";

const containerCss = css``;

function App() {
  return (
    <div css={containerCss}>
      <Global styles={globalCss} />
      <ProjectsRoute />
      <ProjectRoute />
      <WebIntentSyncRoute />
      <BusyOverlay />
    </div>
  );
}

export default App;
