import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { Scene } from "../types";
import { useAtomValue } from "jotai";
import { currentTimeState } from "../state/currentTimeState";
import { Field } from "./Form";
import { formatTimecode } from "../utils/formatTimecode";
import { isUndefined } from "lodash";
import { parseTimecode } from "../utils/parseTimecode";
import { useInputState } from "../hooks/useInputState";

interface Props {
  header?: string;
  scene?: Partial<Scene>;
  submitLabel: string;
  onSubmit: (scene: Scene) => void | Promise<void>;
}

const DEFAULT_VALUE: Partial<Scene> = {
  title: "",
  description: "",
};

export const SceneForm: FC<Props> = ({
  header,
  scene = DEFAULT_VALUE,
  submitLabel,
  onSubmit,
}) => {
  const titleRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  const [title, onChangeTitle] = useInputState(scene.title ?? "");
  const [description, onChangeDescription] = useInputState(
    scene.description ?? ""
  );
  const [intent, onChangeIntent] = useInputState(scene.intent ?? "");
  const [location, onChangeLocation] = useInputState(scene.location ?? "");

  const currentTime = useAtomValue(currentTimeState);
  const [timestamp, setTimestamp] = useState(
    formatTimecode(isUndefined(scene.timestamp) ? currentTime : scene.timestamp)
  );
  const onChangeTimestamp = (event: ChangeEvent<HTMLInputElement>) => {
    setTimestamp(event.target.value);
  };

  useEffect(() => {
    if (isUndefined(scene.timestamp)) {
      setTimestamp(formatTimecode(currentTime));
    }
  }, [scene.timestamp, currentTime]);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      ...scene,
      title,
      description,
      intent,
      location,
      timestamp: parseTimecode(timestamp),
    });
  };

  return (
    <form onSubmit={onFormSubmit}>
      {header && <legend>{header}</legend>}
      <Field>
        <label htmlFor="timestamp">Timestamp</label>
        <input
          readOnly={isUndefined(scene.timestamp)}
          type="text"
          name="timestamp"
          autoComplete="off"
          value={timestamp}
          onChange={onChangeTimestamp}
        />
      </Field>

      <Field>
        <label htmlFor="title">Title</label>
        <input
          ref={titleRef}
          type="text"
          name="title"
          autoComplete="off"
          value={title}
          onChange={onChangeTitle}
        />
      </Field>

      <Field>
        <label htmlFor="intent">Intent</label>
        <input
          type="text"
          name="intent"
          autoComplete="off"
          value={intent}
          onChange={onChangeIntent}
        />
      </Field>

      <Field>
        <label htmlFor="location">Location</label>
        <input
          type="text"
          name="location"
          autoComplete="off"
          value={location}
          onChange={onChangeLocation}
        />
      </Field>

      <Field>
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          value={description}
          onChange={onChangeDescription}
        />
      </Field>

      <div data-form-actions>
        <button type="submit">{submitLabel}</button>
      </div>
    </form>
  );
};
