import { useAtomValue } from "jotai";
import { FC } from "react";
import { busyState } from "../state/busyState";
import { css } from "@emotion/react";
import colors from "../styles/colors";
import { Spinner } from "./Spinner";

const overlayCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 0.8;
  background-color: ${colors.slate[50]};
  z-index: 1000;
`;

export const BusyOverlay: FC = () => {
  const busy = useAtomValue(busyState);

  if (!busy) {
    return null;
  }

  return (
    <div css={overlayCss}>
      <Spinner reason={busy} />
    </div>
  );
};
