import { useLiveQuery } from "dexie-react-hooks";
import { useMemo } from "react";
import { db } from "../db";
import { sortBy } from "lodash";
import { ShotListItem } from "../components/ShotListItemDisplay";

export const useShotListItems = (musicVideoId: number) => {
  const scenes = useLiveQuery(() =>
    db.scenes.where({ musicVideoId }).toArray()
  );
  const shots = useLiveQuery(() => db.shots.where({ musicVideoId }).toArray());
  const notes = useLiveQuery(() => db.notes.where({ musicVideoId }).toArray());

  const sorted = useMemo(() => {
    const all: ShotListItem[] = [
      ...(scenes ?? []).map(
        (scene) => ({ ...scene, type: "scene" } as ShotListItem)
      ),
      ...(shots ?? []).map(
        (shot) => ({ ...shot, type: "shot" } as ShotListItem)
      ),
      ...(notes ?? []).map(
        (note) => ({ ...note, type: "note" } as ShotListItem)
      ),
    ];

    return sortBy(all, "timestamp");
  }, [shots, scenes, notes]);

  return sorted;
};
